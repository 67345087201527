'use client';

import { boot as bootIntercom } from '@/components/_common/Intercom/intercom';
import { useAuth } from '@/context/AuthContextProvider';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import { load as loadIntercom } from './intercom';

export const IntercomProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userId, email, givenName, lastName, company } = useAuth();
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (typeof window !== 'undefined' && userId) {
      loadIntercom();
      bootIntercom({
        email: email,
        name: `${givenName} ${lastName}`,
        user_id: userId,
        hide_default_launcher: isXs ? true : false,
        user_type: 'partner',
        company: {
          company_id: company,
          name: company,
        },
      });
    }
  }, [userId, email, givenName, lastName, company, isXs]);

  return children;
};
