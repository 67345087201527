import { Theme } from '@mui/material/styles';

const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          '& a': {
            color: theme.palette.primary.main,
          },
        },
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            color: theme.palette.text.primary,
            [theme.breakpoints.down('sm')]: {
              fontSize: '64px',
            },
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            color: theme.palette.text.primary,
            [theme.breakpoints.down('sm')]: {
              fontSize: '48px',
            },
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            color: theme.palette.text.primary,
            [theme.breakpoints.down('sm')]: {
              fontSize: '32px',
            },
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            color: theme.palette.text.primary,
            [theme.breakpoints.down('sm')]: {
              fontSize: '24px',
            },
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            color: theme.palette.text.primary,
            [theme.breakpoints.down('sm')]: {
              fontSize: '18px',
            },
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            color: theme.palette.text.primary,
          },
        },
        {
          props: { color: 'success' },
          style: {
            color: theme.palette.success.main,
          },
        },
      ],
    },
  };
};

export default Typography;
