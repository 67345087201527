import { Theme } from '@mui/material/styles';

// ** Overrides Imports
import MuiAccordion from './accordion';
import MuiButton from './button';
import MuiCard from './card';
import MuiChip from './chip';
import MuiDatePicker from './datePicker';
import MuiDialog from './dialog';
import MuiIconButton from './iconButton';
import MuiLinearProgress from './linearProgress';
import MuiMenu from './menu';
import MuiPaper from './paper';
import MuiSelect from './select';
import MuiSkeleton from './skeleton';
import MuiSwitch from './switch';
import MuiTable from './table';
import MuiTabs from './tabs';
import MuiTextField from './textField';
import MuiTooltip from './tooltip';
import MuiTypography from './typography';

const Overrides = (theme: Theme) => {
  const cards = MuiCard(theme);
  const chip = MuiChip(theme);
  const button = MuiButton(theme);
  const iconButton = MuiIconButton(theme);
  const textField = MuiTextField(theme);
  const switchButton = MuiSwitch(theme);
  const linearProgress = MuiLinearProgress(theme);
  const select = MuiSelect(theme);
  const datePicker = MuiDatePicker(theme);
  const accordion = MuiAccordion(theme);
  const skeleton = MuiSkeleton(theme);
  const tooltip = MuiTooltip(theme);
  const paper = MuiPaper(theme);
  const dialog = MuiDialog(theme);
  const typography = MuiTypography(theme);
  const table = MuiTable(theme);
  const menu = MuiMenu(theme);
  const tabs = MuiTabs(theme);

  return Object.assign(
    cards,
    chip,
    button,
    iconButton,
    textField,
    switchButton,
    linearProgress,
    select,
    datePicker,
    accordion,
    skeleton,
    tooltip,
    paper,
    dialog,
    typography,
    table,
    menu,
    tabs
  );
};

export default Overrides;
