'use client';

import { Loader } from '@/components/_common/Loader/Loader';
import { useCanvas } from '@/context/CanvasContextProvider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import styles from './canvas.module.scss';

function Canvas() {
  const theme = useTheme();
  const {
    showCanvas,
    setShowCanvas,
    showCanvasHeader,
    canvasContent,
    justifyContent,
    loading,
  } = useCanvas();

  const handleClose = () => setShowCanvas(false);

  return (
    <Modal
      open={showCanvas}
      onClose={handleClose}
      sx={{ width: '100%', height: '100%' }}
    >
      <Box sx={{ backgroundColor: theme.palette.background.canvas }}>
        {showCanvasHeader && <nav className={styles.canvasHeader}></nav>}

        <Box
          className={styles.canvasContent}
          style={{ justifyContent: `${justifyContent}` }}
        >
          {loading ? (
            <Box
              style={{
                width: '100%',
                height: '100%',
                background: theme.palette.background.canvas,
              }}
            >
              <Loader
                backgroundColor={theme.palette.background.default}
                mainColor={theme.palette.primary.main}
                tickColor={theme.palette.primary.main}
              />
            </Box>
          ) : null}
          {canvasContent}
        </Box>
      </Box>
    </Modal>
  );
}

export default Canvas;
