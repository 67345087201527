'use client';

import { useAuth } from '@/context/AuthContextProvider';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';

const GA_ADS = 'cookielawinfo-checkbox-ads';
const GA_ANALYTICS = 'cookielawinfo-checkbox-analytics';
const DEFAULT_COOKIE_EXPIRE_DATE = 365;
const ENVS = ['production', 'staging'];

type Params = {
  params: {
    hotjarEnabled?: boolean;
  };
};

/**
 * Scripts: Hotjar and GTM scripts for the Partner Portal
 * Had to isolate into a client component because it has a useEffect hook which can only be used in a client component
 */
function Tracking({ params: { hotjarEnabled = false } }) {
  const { isAuthenticated, userId, givenName, email, company } = useAuth();
  const options = {
    id: 3603524,
    sv: 6,
    debug: false,
    nonce: 'Nonce',
  };

  const setGaCookies = (status: string) => {
    Cookies.set(GA_ADS, status, {
      expires: DEFAULT_COOKIE_EXPIRE_DATE,
    });
    Cookies.set(GA_ANALYTICS, status, {
      expires: DEFAULT_COOKIE_EXPIRE_DATE,
    });
  };

  // Push the user details to the dataLayer
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.dataLayer) {
      (window as any).dataLayer.push({
        userId: userId,
        userName: givenName,
        userType: 'partner',
        userEmail: email,
        userCompany: company,
        event: 'userIdSet',
      });
    }
  }, [isAuthenticated, userId]);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_APPLICATION_STAGE &&
      ENVS.includes(process.env.NEXT_PUBLIC_APPLICATION_STAGE)
    ) {
      TagManager.initialize({ gtmId: 'GTM-5BMCLT7' });
      setGaCookies('yes');
    }
    if (
      process.env.NEXT_PUBLIC_APPLICATION_STAGE &&
      ENVS.includes(process.env.NEXT_PUBLIC_APPLICATION_STAGE)
    ) {
      hotjar.initialize(options);
    }
  }, []);

  useEffect(() => {
    if (
      hotjarEnabled &&
      isAuthenticated &&
      userId &&
      process.env.NEXT_PUBLIC_APPLICATION_STAGE &&
      ENVS.includes(process.env.NEXT_PUBLIC_APPLICATION_STAGE)
    ) {
      hotjar.identify('USER_ID', { userProperty: userId });
    }
  }, [isAuthenticated, userId]);

  return <></>;
}

export default Tracking;
