import './loader.css';

interface LoaderProps {
  /**
   * determines the color of the and top tick and windows in the logo
   */
  mainColor?: string | null;
  /**
   * determines the color of the bottom tick
   */
  tickColor?: string | null;
  /**
   * determines the background of the loader
   */
  backgroundColor?: string | null;
}

/**
 * Displays a logo on the screen in whatever color and location wanted
 */
export const Loader = ({
  mainColor = null,
  tickColor = null,
  backgroundColor = null,
  ...props
}: LoaderProps) => {
  const loaderStyle = {
    '--loader-main': mainColor,
    '--loader-tick': tickColor,
    '--loader-background': backgroundColor,
  } as React.CSSProperties;
  return (
    <div className="kotini-loader-container" {...props} style={loaderStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="57.4"
        height="58.79"
        viewBox="0 0 57.4 58.79"
      >
        <g className="loader-logo-outer">
          <polyline
            className="loader-logo-tick"
            points="17.58 42.01 28.7 53.13 54.57 27.26"
          />
          <polyline
            className="loader-logo-roof"
            points="39.82 16.78 28.7 5.66 2.83 31.53"
          />
          <polyline
            className="loader-logo-chimney"
            points="14.73 19.63 14.73 5.06"
          />
        </g>
        <g className="loader-logo-windows">
          <rect x="21.93" y="30.86" width="5.31" height="5.31" />
          <rect x="30.16" y="22.62" width="5.31" height="5.31" />
          <rect x="21.93" y="22.62" width="5.31" height="5.31" />
          <rect x="30.16" y="30.86" width="5.31" height="5.31" />
        </g>
      </svg>
    </div>
  );
};
