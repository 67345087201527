'use client'; // Error components must be Client Components

import BlankLayout from '@/components/_layout/Blank/BlankLayout';
import { useEffect } from 'react';

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    // Add Sentry.captureException error to send event to sentry since next.js 13
    // automatically create an error boundary to handle all errors
    // Sentry.captureException(error);
  }, [error]);

  return (
    <BlankLayout>
      <h3>Oops... Something’s gone wrong</h3>
    </BlankLayout>
  );
}
