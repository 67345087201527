import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { Dispatch } from 'react';

/**
 * KeycloakConfig configures the connection to the Keycloak server.
 */
const keycloakConfig: KeycloakConfig = {
  realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM || '',
  clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT || '',
  url: process.env.NEXT_PUBLIC_KEYCLOAK_URL || '',
};

const useKeycloak = () => {
  const initializeKeycloak = async (
    setAccessToken?: Dispatch<string>,
    forceLogin?: boolean
  ) => {
    try {
      /**
       * KeycloakInitOptions configures the Keycloak client.
       */
      const keycloakInitOptions: KeycloakInitOptions = {
        onLoad: forceLogin ? 'login-required' : 'check-sso',
        silentCheckSsoFallback: false,
      };

      /**
       * Initialize the Keycloak instance
       */
      const keycloak = new Keycloak(keycloakConfig);
      const isAuthenticatedResponse = await keycloak.init(keycloakInitOptions);

      if (!isAuthenticatedResponse && forceLogin) {
        console.log('user is not yet authenticated. forwarding user to login.');
        keycloak.login();
      }

      // handle token refresh
      const refreshToken = async () => {
        try {
          const refreshed = await keycloak.updateToken(60);
          if (refreshed) {
            // Token was successfully refreshed
            setAccessToken && keycloak?.token && setAccessToken(keycloak.token);
            keycloak?.token &&
              sessionStorage.setItem('accessToken', keycloak.token);
            // console.log('Token refreshed');
            return keycloak.token;
          } else {
            // console.log('Token is still valid');
            return keycloak.token;
          }
        } catch (error) {
          console.error(
            'Failed to refresh the token, or the session has expired, Logging in...'
          );
          forceLogin && keycloak.login();
        }
      };

      keycloak?.token && sessionStorage.setItem('accessToken', keycloak.token);
      setInterval(refreshToken, 60000); // Refresh token every 1 minute

      return {
        isAuthenticatedResponse,
        keycloak,
        refreshToken,
      };
    } catch {
      return {
        isAuthenticatedResponse: false,
        keycloak: null,
        refreshToken: null,
      };
    }
  };

  return {
    initializeKeycloak,
  };
};

export default useKeycloak;
