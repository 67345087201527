'use client';

import { Loader } from '@/components/_common/Loader/Loader';
import { useModal } from '@/context/ModalContextProvider';
import styles from './modal.module.scss';

function Modal() {
  const {
    showModal,
    setShowModal,
    title,
    setTitle,
    modalContent,
    setModalContent,
    onHide,
    setOnHide,
    loading,
    setLoading,
  } = useModal();

  return (
    <div
      className={`${styles.modalContainer} ${
        showModal ? styles.open : styles.closed
      }`}
    >
      <div className={styles.modal}>
        <nav className={styles.modalHeader}>
          <h3>{title}</h3>
          <div className={styles.spacer}></div>
        </nav>
        <div className={styles.modalContent}>
          {loading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                background: 'var(--kotini-dark-green)',
              }}
            >
              <Loader />
            </div>
          ) : (
            modalContent || null
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
