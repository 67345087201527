import { Theme } from '@mui/material/styles';

const Skeleton = (theme: Theme) => {
  return {
    MuiSkeleton: {
      defaultProps: {},
      styleOverrides: {
        root: {
          background: theme.palette.divider,
        },
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            borderRadius: 0,
          },
        },
        {
          props: { color: 'white' },
          style: {
            background: theme.palette.background.paper + '1a',
          },
        },
      ],
    },
  };
};

export default Skeleton;
