'use client';

import AppLoader from '@/components/_common/AppLoader/AppLoader';
import { useAuth } from '@/context/AuthContextProvider';
import { GET_USER } from '@/services/queries';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { useEffect } from 'react';
import AppBar from './components/AppBar';
import SideBar from './components/SideBar';

type Link = {
  text: string;
  href: string;
  icon: React.ElementType;
};

type Props = {
  links: Link[];
  linksBottom: Link[];
  children?: React.ReactNode;
};

/**
 * Component: Side Bar
 */
export default function Nav({ links, linksBottom, children }: Props) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const { email } = useAuth();
  const { loading: userDataLoading } = useQuery(GET_USER, {
    skip: !email,
  });

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading || userDataLoading) return <AppLoader />;
  return (
    <Box
      sx={{
        pl: 2,
        pr: { xs: 2, sm: 0 },
        display: 'flex',
        height: '100svh',
        gap: 2,
        overflowY: { xs: 'scroll', sm: 'visible' },
        flexDirection: { xs: 'column', sm: 'row' },
        '*::-webkit-scrollbar': {
          width: '8px',
        },
        '*::-webkit-scrollbar-track': {},
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.divider,
        },
      }}
    >
      {isXs ? (
        // Render your component for 'xs' breakpoint
        <AppBar links={[...links, ...linksBottom]} />
      ) : (
        // Render your component for other breakpoints
        <SideBar links={links} linksBottom={linksBottom} />
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
          borderRaius: 2,
          pr: { xs: 0, sm: 2 },
          overflowY: { xs: 'visible', sm: 'scroll' },
          position: 'relative',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
