import { Theme } from '@mui/material/styles';

const Button = (theme: Theme) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            height: '64px',
            lineHeight: '18px',
            padding: '8px 24px',
            borderRadius: '16px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '40px',
            borderRadius: '8px',
            lineHeight: '18px',
            padding: '8px 20px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            height: '32px',
            borderRadius: '4px',
            lineHeight: '14px',
            padding: '4px 8px',
          },
        },

        {
          props: { color: 'inherit', variant: 'contained' },
          style: {
            background: theme.palette.text.primary + '0d',
            '&:hover': {
              background: theme.palette.text.primary + '1a',
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            background: theme.palette.background.default,
            color: theme.palette.primary.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            ':hover': {
              background: theme.palette.divider,
            },
            borderColor: theme.palette.secondary.main,
            '&:hover': { borderColor: theme.palette.secondary.dark },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: '2px',
            '&:hover': { borderWidth: '2px' },
          },
        },
        {
          props: { color: 'inherit', variant: 'contained' },
          style: {
            background: theme.palette.text.primary + '0d',
            '&:hover': {
              background: theme.palette.text.primary + '1a',
            },
          },
        },
      ],
    },
  };
};

export default Button;
