import { Theme } from '@mui/material/styles';

const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      defaultProps: { elevation: 3 },
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { elevation: 0 },
          style: {
            backgroundColor: 'transparent',
          },
        },
        {
          props: { elevation: 1 },
          style: {
            backgroundColor: theme.palette.background.default,
          },
        },
        {
          props: { elevation: 2 },
          style: {
            backgroundColor: theme.palette.background.canvas,
          },
        },
        {
          props: { elevation: 3 },
          style: {
            backgroundColor: theme.palette.background.paper,
          },
        },
        {
          props: { elevation: 4 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 2px' + theme.palette.divider,
          },
        },
        {
          props: { elevation: 5 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 4px' + theme.palette.divider,
          },
        },
        {
          props: { elevation: 6 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.divider +
              ', 0px 0px 0px 8px' +
              theme.palette.divider,
          },
        },
        {
          props: { elevation: 7 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 2px' + theme.palette.text.disabled,
          },
        },
        {
          props: { elevation: 8 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 4px' + theme.palette.text.disabled,
          },
        },
        {
          props: { elevation: 9 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.text.disabled +
              ', 0px 0px 0px 8px' +
              theme.palette.divider,
          },
        },
        {
          props: { elevation: 10 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 2px' + theme.palette.text.primary,
          },
        },
        {
          props: { elevation: 11 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 4px' + theme.palette.text.primary,
          },
        },
        {
          props: { elevation: 12 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.text.primary +
              ', 0px 0px 0px 8px' +
              theme.palette.divider,
          },
        },
        {
          props: { elevation: 13 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.text.primary +
              ', 0px 0px 0px 8px' +
              theme.palette.text.disabled,
          },
        },
        {
          props: { elevation: 14 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 2px' + theme.palette.primary.main,
          },
        },
        {
          props: { elevation: 15 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 0px 4px' + theme.palette.primary.main,
          },
        },
        {
          props: { elevation: 16 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.primary.main +
              ', 0px 0px 0px 8px' +
              theme.palette.divider,
          },
        },
        {
          props: { elevation: 17 },
          style: {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.primary.main +
              ', 0px 0px 0px 8px' +
              theme.palette.primary.main +
              '40',
          },
        },
      ],
    },
  };
};

export default Paper;
