import { Theme } from '@mui/material/styles';

const LinearProgress = (theme: Theme) => {
  return {
    MuiLinearProgress: {
      defaultProps: {
        variant: 'determinate',
        color: 'success',
      },
      styleOverrides: {
        root: {},
        bar: {},
      },
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            background: theme.palette.background.default,
            '& .MuiLinearProgress-bar': {
              background: theme.palette.primary.main,
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            background: theme.palette.error.main + '1a',
          },
        },
        {
          props: { color: 'warning' },
          style: {
            background: theme.palette.warning.main + '1a',
          },
        },
        {
          props: { color: 'info' },
          style: {
            background: theme.palette.info.main + '1a',
          },
        },
        {
          props: { color: 'success' },
          style: {
            background: theme.palette.success.main + '1a',
          },
        },
        {
          props: { color: 'white' },
          style: {
            background: theme.palette.white.main + '0d',
            '& .MuiLinearProgress-bar': {
              background: theme.palette.white.main,
            },
          },
        },
      ],
    },
  };
};

export default LinearProgress;
