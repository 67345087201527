const Palette = {
  customer: {
    text: {
      primary: '#17222C',
      secondary: '#17222C99',
      disabled: '#17222C40',
    },
    primary: {
      main: '#0B1621',
      dark: '#07131E',
      light: '#17222C',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#0B16210d',
      dark: '#0B16211a',
      light: '#0B162107',
      contrastText: '#0B1621',
    },
    success: {
      main: '#064550',
      dark: '#05363E',
      light: '#1F5862',
      contrastText: '#CCEDEB',
    },
    error: {
      main: '#BC3636',
      dark: '#942626',
      light: '#D14848',
      contrastText: '#FFCACA',
    },
    warning: {
      main: '#8C5018',
      dark: '#744010',
      light: '#AB6624',
      contrastText: '#FFD371',
    },
    info: {
      main: '#18396C',
      dark: '#122E59',
      light: '#2F4D7B',
      contrastText: '#9BE7FF',
    },
    white: {
      main: '#ffffff',
      dark: '#ffffff',
      light: '#ffffff',
      contrastText: '#0B1621',
    },
    background: {
      default: '#0B162105',
      paper: '#ffffff',
      nav: '#ffffff',
      canvas: '#FCFAFF',
      backdrop: '#0B162122',
    },
    action: {
      active: '#0B1621',
      disabled: '#0B162140',
      disabledBackground: '#0B16210d',
      hover: '#0B162107',
      focus: '#0B16211a',
      select: '#0B162126',
    },
    divider: '#0B16210d',
  },
  partner: {
    text: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF99',
      disabled: '#FFFFFF40',
    },
    primary: {
      main: '#B2E4E1',
      dark: '#99DBD7',
      light: '#CCEDEB',
      contrastText: '#0B1621',
    },
    secondary: {
      main: '#B2E4E10d',
      dark: '#B2E4E11a',
      light: '#B2E4E107',
      contrastText: '#B2E4E1',
    },
    success: {
      main: '#99DBD7',
      dark: '#80D3CD',
      light: '#B2E4E1',
      contrastText: '#064550',
    },
    error: {
      main: '#FFCACA',
      dark: '#FF9D9D',
      light: '#FFD5D5',
      contrastText: '#BC3636',
    },
    warning: {
      main: '#FFD371',
      dark: '#FFC33D',
      light: '#FFDC8D',
      contrastText: '#8C5018',
    },
    info: {
      main: '#9BE7FF',
      dark: '#61C7E8',
      light: '#B8EEFF',
      contrastText: '#18396C',
    },
    white: {
      main: '#ffffff',
      dark: '#ffffffDF',
      light: '#ffffff',
      contrastText: '#0B1621',
    },
    background: {
      default: '#ffffff07',
      paper: '#0B1621',
      nav: '#111C27',
      canvas: '#111C27',
      backdrop: '#ffffff1a',
    },
    action: {
      active: '#ffffff',
      disabled: '#ffffff99',
      disabledBackground: '#ffffff0d',
      hover: '#ffffff0d',
      focus: '#ffffff1a',
      select: '#ffffff26',
    },
    divider: '#ffffff0d',
  },
};

export default Palette;
