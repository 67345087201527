import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Loader } from '../Loader/Loader';

function AppLoader() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        zIndex: 100,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: theme.palette.background.nav,
          opacity: 1,
          width: '96px',
          height: '96px',
          borderRadius: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loader
          mainColor={theme.palette.primary.main}
          tickColor={theme.palette.success.main}
          backgroundColor={'transparent'}
        />
      </div>
    </Box>
  );
}

export default AppLoader;
