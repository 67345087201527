import { Theme } from '@mui/material/styles';

const TextField = (theme: Theme) => {
  return {
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
      },

      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            background: theme.palette.background.default,
            borderRadius: '16px',
            transition: 'background 0.2s, box-shadow 0.2s',
            '&.Mui-error': {
              background: theme.palette.error.contrastText + '1a',
              '& ::placeholder': {
                color: theme.palette.error.main + '9D',
              },
            },
            '&.Mui-disabled': {
              background: theme.palette.action.disabledBackground,
              boxShadow: 'none',
            },
          },
          '& .MuiInputBase-root:hover': {
            background: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.divider +
              ', 0px 0px 0px 8px' +
              theme.palette.divider,
            '&.Mui-error': {
              background: theme.palette.error.contrastText + '26',
              boxShadow:
                '0px 0px 0px 4px' +
                theme.palette.error.main +
                '0d' +
                ', 0px 0px 0px 8px' +
                theme.palette.error.main +
                '0d',
            },
            '&.Mui-disabled': {
              background: theme.palette.action.disabledBackground,
              boxShadow: 'none',
            },
          },
          '& .MuiInputBase-root.Mui-focused': {
            background: theme.palette.background.paper,
            boxShadow:
              '0px 0px 0px 4px' +
              theme.palette.text.primary +
              ', 0px 0px 0px 8px' +
              theme.palette.divider,
            '&.Mui-error': {
              background: theme.palette.error.contrastText + '34',
              boxShadow:
                '0px 0px 0px 4px' +
                theme.palette.error.main +
                ', 0px 0px 0px 8px' +
                theme.palette.error.main +
                '1a',
            },
          },
          '& .MuiFilledInput-root': {
            '&:before': {
              borderBottom: 'none !important', // Removes the underline on focus
            },
            '&:hover:before': {
              borderBottom: 'none !important', // Removes the underline on hover
            },
            '&:hover:after': {
              borderBottom: 'none !important', // Removes the underline on hover
            },
            '&:after': {
              borderBottom: 'none !important', // Removes the underline when active
            },
          },
          '& .MuiFormHelperText-root': {
            margin: '2px 8px 0px 8px',
            transition: 'margin 0.1s',
            '&.Mui-focused': {
              margin: '4px 8px 0px 8px',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          '&.Mui-focused': {
            fontWeight: 500,
          },
        },
      },
    },
  };
};

export default TextField;
