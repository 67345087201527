import { Theme } from '@mui/material/styles';

const Select = (theme: Theme) => {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          outline: '2px solid' + theme.palette.text.primary + '1a',
        },
        root: {
          '*::-webkit-scrollbar': {
            width: '8px',
          },
          '*::-webkit-scrollbar-track': {
            margin: '16px 0',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.divider,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: '48px',
          '&.Mui-selected': {
            background: theme.palette.background.default,
          },
          '&.Mui-selected:hover': {
            background: theme.palette.divider,
          },
        },
      },
    },
    MuiOutlinedInput: {
      syleOverrides: {
        root: {
          border: 'none',
          borderRadius: '16px',
          background: theme.palette.background.default,
          '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          border: 'none',
          borderRadius: '16px',
          background: theme.palette.background.default,
          '& .MuiOutlinedInput-notchedOutline': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: 'transparent',
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              background: theme.palette.divider,
              borderColor: 'transparent',
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.primary.main,
            },
          },

          '& .MuiInputLabel-shrink': {
            opacity: 0,
          },
          '& .MuiInputLabel-shrink.Mui-focused': {
            opacity: 1,
          },

          //error variant
          '&.Mui-focused.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
          '&:hover.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
        },
        select: {
          display: 'flex',
          justifyContent: 'flex-start',
          gap: 8,
        },
      },
    },
  };
};

export default Select;
