import { Theme } from '@mui/material/styles';

const Tabs = (theme: Theme) => {
  return {
    MuiTabs: {
      defaultProps: {},
      styleOverrides: {
        root: {
          height: 'fit-content',
          minHeight: '32px',
          alignItems: 'center',
        },
        scrollableX: {
          '& .MuiTabs-flexContainer': { gap: '8px' },
          scroller: {
            height: 'fit-content',
          },
          '& .MuiTab-root': {
            borderRadius: '8px 8px 0 0 ',
          },
        },
        scrollButtons: {
          marginTop: '1px',
          width: '32px',
          height: '32px',
          '&.Mui-disabled': { opacity: 0.3 },
          color: theme.palette.text.primary,
        },
      },
    },
    MuiTab: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: '8px',
          height: 'fit-content',
          width: 'fit-content',
          minHeight: '32px',
          minWidth: '48px',
        },
      },
    },
  };
};
export default Tabs;
