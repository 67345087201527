import { Theme } from '@mui/material/styles';

const Menu = (theme: Theme) => {
  return {
    MuiMenu: {
      defaultProps: {
        PaperProps: { elevation: 5 },
      },
    },
  };
};

export default Menu;
