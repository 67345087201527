import { Theme } from '@mui/material/styles';

const Switch = (theme: Theme) => {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: '6px',
          '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-track': {
            border: '1px red solid',
          },
        },
        thumb: { background: theme.palette.success.contrastText },
        track: {
          height: '26px',
          borderRadius: '13px',
          background: theme.palette.success.main,
          opacity: 0.1,
        },
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              opacity: 1,
            },
          },
        },
        variants: [],
      },
    },
  };
};

export default Switch;
