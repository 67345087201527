'use client';

import { Dispatch, createContext, useContext, useState } from 'react';

/**
 * ModalContextValues defines the structure for the default values of the ModalContext
 */
interface ModalContextValues {
  showModal: boolean;
  setShowModal: Dispatch<boolean>;
  title: string;
  setTitle: Dispatch<string>;
  modalContent: any;
  setModalContent: Dispatch<any>;
  onHide: { title: string; content: any } | null;
  setOnHide: Dispatch<{ title: string; content: any } | null>;
  loading: boolean;
  setLoading: Dispatch<boolean>;
}

/**
 * ModalContext is the context exposed by the ModalContextProvider
 */
export const ModalContext = createContext<ModalContextValues | undefined>(
  undefined
);

/**
 * The props that must be passed to create the ModalContextProvider
 */
interface ModalContextProviderProps {
  children: React.ReactNode;
}

/**
 * ModalContextProvider is responsible for managing the modal state
 */
const ModalContextProvider = (props: ModalContextProviderProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<any>(null);
  const [onHide, setOnHide] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ModalContext.Provider
      value={{
        showModal,
        setShowModal,
        title,
        setTitle,
        modalContent,
        setModalContent,
        onHide,
        setOnHide,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

/**
 * Custom useModal hook for easy access to the ModalContext
 */
const useModal = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('useModal must be used within a ModalContextProvider');
  }
  return modalContext;
};

export { ModalContextProvider, useModal };
