import { alpha, Theme } from '@mui/material/styles';

const IconButton = (theme: Theme) => {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            height: '64px',
            width: '64px',
            minHeight: '64px',
            minWidth: '64px',
            borderRadius: '16px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            height: '40px',
            width: '40px',
            borderRadius: '8px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            borderRadius: '4px',
            height: '32px',
            width: '32px',
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            background: theme.palette.primary.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.contrastText },
            ':hover': {
              background: theme.palette.primary.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            background: theme.palette.primary.contrastText,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: alpha(theme.palette.primary.main, 0.5),
            '&:hover': {
              borderColor: theme.palette.primary.main,
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            '&:hover': {
              '& .MuiSvgIcon-root': { fill: theme.palette.primary.dark },
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            background: theme.palette.background.default,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            ':hover': {
              background: theme.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            background: theme.palette.background.default,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.main },
            ':hover': {
              background: theme.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            background: theme.palette.secondary.main,
            '& .MuiSvgIcon-root': {
              fill: theme.palette.secondary.contrastText,
            },
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: theme.palette.secondary.main,
            '&:hover': {
              background: theme.palette.secondary.dark,
              borderColor: theme.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            background: theme.palette.secondary.main,
            '& .MuiSvgIcon-root': {
              fill: theme.palette.secondary.contrastText,
            },
            '&:hover': {
              background: theme.palette.secondary.dark,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'error' },
          style: {
            background: theme.palette.error.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.primary.contrastText },
            ':hover': {
              background: theme.palette.error.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.error.main,
            },
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: alpha(theme.palette.error.main, 0.5),
            '&:hover': {
              borderColor: theme.palette.error.main,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'warning' },
          style: {
            background: theme.palette.warning.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.warning.contrastText },
            ':hover': {
              background: theme.palette.warning.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'warning' },
          style: {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.warning.main,
            },
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: alpha(theme.palette.warning.main, 0.5),
            '&:hover': {
              borderColor: theme.palette.warning.main,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'info' },
          style: {
            background: theme.palette.info.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.info.contrastText },
            ':hover': {
              background: theme.palette.info.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.info.main,
            },
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: alpha(theme.palette.info.main, 0.5),
            '&:hover': {
              borderColor: theme.palette.info.main,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            background: theme.palette.success.main,
            '& .MuiSvgIcon-root': { fill: theme.palette.success.contrastText },
            ':hover': {
              background: theme.palette.success.dark,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'success' },
          style: {
            '& .MuiSvgIcon-root': {
              fill: theme.palette.success.main,
            },
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: alpha(theme.palette.success.main, 0.5),
            '&:hover': {
              borderColor: theme.palette.success.main,
            },
          },
        },
      ],
    },
  };
};

export default IconButton;
