import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    user {
      userId
      givenName
      familyName
      email
      phone
    }
  }
`;

export const GET_TRANSACTION = gql`
  query GetTransaction($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      name
      uprn
      address
      transaction {
        name
        type
        linkedTransactionId
        linkedTransactionName
        status
        vendors {
          name
          contact {
            email
          }
          tasks {
            name
            complete
          }
        }
        buyers {
          name
          contact {
            email
          }
          tasks {
            name
            complete
          }
        }
        property {
          address {
            lineOne
            lineTwo
            city
            postcode
          }
          basic {
            tenure
            issues
            issuesDetails
            footpath
            footpathDetails
            safety
            safetyDetails
          }
          leasehold {
            length
            groundRent
            serviceCharge
            agent
            agentContact
          }
        }
        engagement {
          board
          keys
          feeDescription
          priceType
          agreedPrice
          minimumPrice
          maximumPrice
          conflict
          conflictDetail
        }
        documents {
          name
          reference
        }
      }
      property {
        line1
        line2
        line3
        town
        postcode
        uprn
      }
    }
  }
`;

export const GET_FILE = gql`
  query GetFile($reference: String!) {
    getFile(reference: $reference) {
      name
      url
    }
  }
`;

export const GET_ACTIVITY = gql`
  query GetActivity {
    activity {
      title
      body
      image
      timestamp
    }
  }
`;

export const TRANSACTION_SUMMARY = gql`
  query transactionSummary($transactionId: UUID!) {
    transaction(transactionId: $transactionId) {
      transactionId
      type
      status
      imageUrl
      address
      shortAddress
      created
    }
  }
`;

export const GET_TRANSACTION_TASKS = gql`
  query transactionTasks($transactionId: String!) {
    transactionTasks(transactionId: $transactionId) {
      tasks {
        taskId
        name
        description
        slug
        issuer
        issuerId
        company
        companyId
        image
        domain
        key
        created
        predecessor
        status
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_NTS_DATA = gql`
  query propertyNtsData($transactionId: String!) {
    propertyNtsData(transactionId: $transactionId) {
      title
      slug
      groups {
        title
        slug
        entries {
          title
          value
          slug
        }
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_ALL_DATA = gql`
  query propertyAllData($transactionId: String!) {
    propertyAllData(transactionId: $transactionId) {
      title
      slug
      groups {
        title
        slug
        entries {
          title
          value
          slug
        }
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_TA6ed4_DATA = gql`
  query propertyTa6ed4Data($transactionId: String!) {
    propertyTa6ed4Data(transactionId: $transactionId) {
      title
      slug
      groups {
        title
        slug
        entries {
          title
          value
          slug
        }
      }
    }
  }
`;

export const FETCH_DOCUMENT = gql`
  query fetchDocument($documentId: UUID!, $contentDisposition: String) {
    fetchDocument(
      documentId: $documentId
      contentDisposition: $contentDisposition
    ) {
      domain
      documentId
      created
      key
      status
      fileName
      displayName
      description
      mimeType
      documentType
      ownerId
      ownerName
      presignedUrl
      file
    }
  }
`;
