import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/BarChart.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/Dashboard.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/Person.js");
import(/* webpackMode: "eager" */ "/app/src/components/_common/DataDog/DataDog.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/_common/Intercom/IntercomProvider.ts");
import(/* webpackMode: "eager" */ "/app/src/components/_layout/Canvas/Canvas.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/_layout/Modal/Modal.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/_layout/Nav/Nav.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/ApolloContextProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/AuthContextProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/CanvasContextProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/ModalContextProvider.tsx")