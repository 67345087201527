'use client';

import useKeycloak from '@/hooks/useKeycloak';
import { createContext, useContext, useEffect, useState } from 'react';

/**
 * AuthContextValues defines the structure for the default values of the AuthContext
 */
interface AuthContextValues {
  isAuthenticated: boolean;
  accessToken: string;
  email: string;
  company: string;
  phone: string;
  givenName: string;
  lastName: string;
  userId: string;
  logout: () => void;
  login: (options?: { action?: string }) => void;
  refresh: () => void;
}

/**
 * defaultAuthContextValues defines the default values for the AuthContext
 */
const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  accessToken: '',
  email: '',
  company: '',
  phone: '',
  givenName: '',
  lastName: '',
  userId: '',
  logout: () => void {},
  login: (options?: { action?: string }) => void {},
  refresh: () => void {},
};

/**
 * AuthContext is the context exposed by the AuthContextProvider
 */
export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
);

/**
 * The props that must be passed to create the AuthContextProvider
 */
interface AuthContextProviderProps {
  children: React.ReactNode;
  forceLogin?: boolean;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 */
const AuthContextProvider = ({
  children,
  forceLogin = true,
}: AuthContextProviderProps) => {
  const { initializeKeycloak } = useKeycloak();
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [givenName, setGivenName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [accessToken, setAccessToken] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [login, setLogin] = useState<() => void>(() => void {});
  const [logout, setLogout] = useState<() => void>(() => void {});
  const [refresh, setRefresh] = useState<() => void>(() => void {});

  const seAuthState = (
    isAuthenticatedResponse: boolean,
    keycloak: any,
    refreshToken: any
  ) => {
    setAuthenticated(isAuthenticatedResponse);
    keycloak?.token && setAccessToken(keycloak.token);
    setEmail(keycloak?.idTokenParsed?.email);
    setPhone(keycloak?.idTokenParsed?.phone);
    setGivenName(keycloak?.idTokenParsed?.given_name);
    setLastName(keycloak?.idTokenParsed?.family_name);
    setCompany(keycloak?.idTokenParsed?.company);
    keycloak?.idTokenParsed?.sub && setUserId(keycloak?.idTokenParsed?.sub);
    setLogout(() => keycloak.logout);
    setLogin(() => keycloak.login);
    setRefresh(() => refreshToken);
  };

  useEffect(() => {
    initializeKeycloak(setAccessToken, forceLogin).then((response) => {
      seAuthState(
        response.isAuthenticatedResponse,
        response.keycloak,
        response.refreshToken
      );
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        email,
        company,
        phone,
        givenName,
        lastName,
        accessToken,
        userId,
        logout,
        login,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom useAuth hook for easy access to the AuthContext
 */
const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }
  return authContext;
};

export { AuthContextProvider, useAuth };
