'use client';

import { Dispatch, createContext, useContext, useState } from 'react';

type JustifyContentType =
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

/**
 * CanvasContextValues defines the structure for the default values of the CanvasContext
 * TO-DO: convert this to a reducer as the state is getting too complex
 * TO-DO: explore to expose a clear or reset function to reset the states
 */
interface CanvasContextValues {
  showCanvas: boolean;
  setShowCanvas: Dispatch<boolean>;
  showCanvasHeader: boolean;
  setShowCanvasHeader: Dispatch<boolean>;
  title: string;
  setTitle: Dispatch<string>;
  canvasContent: any;
  setCanvasContent: Dispatch<any>;
  onHide: { title: string; content: any } | null;
  setOnHide: Dispatch<{ title: string; content: any } | null>;
  justifyContent: JustifyContentType;
  setJustifyContent: Dispatch<JustifyContentType>;
  loading: boolean;
  setLoading: Dispatch<boolean>;
  isFullScreen: boolean;
  setIsFullScreen: Dispatch<boolean>;
}

/**
 * CanvasContext is the context exposed by the CanvasContextProvider
 */
export const CanvasContext = createContext<CanvasContextValues | undefined>(
  undefined
);

/**
 * The props that must be passed to create the CanvasContextProvider
 */
interface CanvasContextProviderProps {
  children: React.ReactNode;
}

/**
 * CanvasContextProvider is responsible for managing the canvas state
 */
const CanvasContextProvider = (props: CanvasContextProviderProps) => {
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [showCanvasHeader, setShowCanvasHeader] = useState<boolean>(true);
  const [title, setTitle] = useState<string>('');
  const [canvasContent, setCanvasContent] = useState<any>(null);
  const [onHide, setOnHide] = useState<any>(null);
  const [justifyContent, setJustifyContent] =
    useState<JustifyContentType>('center');
  const [loading, setLoading] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  return (
    <CanvasContext.Provider
      value={{
        showCanvas,
        setShowCanvas,
        showCanvasHeader,
        setShowCanvasHeader,
        title,
        setTitle,
        canvasContent,
        setCanvasContent,
        onHide,
        setOnHide,
        justifyContent,
        setJustifyContent,
        loading,
        setLoading,
        isFullScreen,
        setIsFullScreen,
      }}
    >
      {props.children}
    </CanvasContext.Provider>
  );
};

/**
 * Custom useCanvas hook for easy access to the CanvasContext
 */
const useCanvas = () => {
  const canvasContext = useContext(CanvasContext);
  if (!canvasContext) {
    throw new Error('useCanvas must be used within a CanvasContextProvider');
  }
  return canvasContext;
};

export { CanvasContextProvider, useCanvas };
