import { Theme } from '@mui/material/styles';

const DatePicker = (theme: Theme) => {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPickersDay: {
      defaultProps: {
        color: 'success',
      },
      styleOverrides: {
        root: { borderRadius: '8px' },
        today: {
          border: 'none !important',
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          borderRadius: '8px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-adornedEnd': {
            paddingRight: '20px !important',
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          margin: '4px 0 !important',
        },
      },
    },
  };
};

export default DatePicker;
