'use client';

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { NextAppDirEmotionCacheProvider } from './EmotionCache';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

type ThemePalette = 'customer' | 'partner' | 'admin';

type ThemeRegistryProps = {
  themePalette?: ThemePalette;
  children: React.ReactNode;
};

declare module '@mui/material/styles' {
  interface PaperVariants {
    info: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface PaperVariantsOptions {
    info?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Card' {
  interface PaperPropsVariantOverrides {
    info: true;
  }
}

const createCustomTheme = (themePalette: ThemePalette) => {
  const paletteString = themePalette || process.env.NEXT_PUBLIC_THEME;
  const themeOptions: ThemeOptions = {
    typography: typography,
    palette:
      palette[paletteString as keyof typeof palette] || palette['customer'],
    shape: {
      borderRadius: 8,
    },
    shadows: Array(25).fill('none') as Shadows,
  };

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(themeOptions);

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    components: { ...overrides(theme) },
  });

  return theme;
};

export default function ThemeRegistry({
  themePalette,
  children,
}: ThemeRegistryProps) {
  const theme = createCustomTheme(themePalette || 'customer');

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'mui' }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
