import { Theme } from '@mui/material/styles';

const Dialog = (theme: Theme) => {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialogActions-root': {
            padding: '8px 16px 16px 16px',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          },
          '& .MuiDialogTitle-root': {
            paddingTop: '20px',
          },
        },
        paper: {
          margin: '16px',
          borderRadius: '24px',
        },
        backdrop: {
          background: theme.palette.background.backdrop,
          backdropFilter: 'blur(5px)',
        },
      },
      variants: [],
    },
  };
};

export default Dialog;
