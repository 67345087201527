import { Theme } from '@mui/material/styles';

const Tooltip = (theme: Theme) => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: '8px 12px',
        },
      },
    },
  };
};

export default Tooltip;
