/**
 * Loader: Loader for the FormWizard component
 */
export default function FormWizardLoader() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        width="390"
        height="548"
        viewBox="0 0 390 548"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="127" width="390" height="421" rx="46" fill="#332B45" />
        <rect width="298" height="58" fill="#332B45" />
        <rect y="74" width="390" height="21" fill="#332B45" />
      </svg>
    </div>
  );
}
