'use client';

import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

/**
 * Scripts: DataDog scripts for the Homemover
 * Had to isolate into a client component because it has a useEffect hook which can only be used in a client component
 */
function DataDogPublic() {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APPLICATION_STAGE === 'production') {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID || '',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || '',
        site: 'datadoghq.eu',
        service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
        env: process.env.NEXT_PUBLIC_APPLICATION_STAGE,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      });

      datadogRum.startSessionReplayRecording();
    }
  }, []);

  return <></>;
}

export default DataDogPublic;
