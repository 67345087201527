import { Outfit } from 'next/font/google';
const outfit = Outfit({
  subsets: ['latin'],
  display: 'swap',
});

const typography = {
  fontFamily: outfit.style.fontFamily,
  h1: {
    fontSize: 96,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 700,
    letterSpacing: '-1.5px',
    lineHeight: '115%',
  },
  h2: {
    fontSize: 64,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: '-0.5px',
    lineHeight: '120%',
  },
  h3: {
    fontSize: 48,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '115%',
  },
  h4: {
    fontSize: 32,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: '0.25px',
    lineHeight: '120%',
  },
  h5: {
    fontSize: 24,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: '0px',
    lineHeight: '130%',
  },
  h6: {
    fontSize: 18,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    lineHeight: '115%',
  },
  body1: {
    fontSize: 16,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 300,
    letterSpacing: '0px',
    lineHeight: '140%',
  },
  body2: {
    fontSize: 14,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 300,
    letterSpacing: '0px',
    lineHeight: '140%',
  },
  subtitle1: {
    fontSize: 16,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: '0.15px',
    lineHeight: '115%',
  },
  subtitle2: {
    fontSize: 14,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 500,
    letterSpacing: '0.1px',
    lineHeight: '115%',
  },
  overline: {
    fontSize: 12,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    letterSpacing: '1px',
    lineHeight: '260%',
  },
  caption: {
    fontSize: 12,
    paragraphIndent: 0,
    paragraphSpacing: 0,
    fontWeight: 400,
    lineHeight: '160%',
  },
  button: {
    large: {
      fontSize: 16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      lineHeight: '18px',
      textTransform: 'none',
    },
    medium: {
      fontSize: 16,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      lineHeight: '18px',
      textTransform: 'none',
    },
    small: {
      fontSize: 12,
      paragraphIndent: 0,
      paragraphSpacing: 0,
      fontWeight: 600,
      lineHeight: '14px',
      textTransform: 'none',
    },
  },
};

export default typography;
